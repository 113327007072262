import React from "react";

// MUI
import { Box, Container } from "@mui/material";
import { row404 } from "../utils/config";
import ProductRow from "../components/ProductRow";

const Nope = (props) => {
  return (
    <Container sx={{ paddingTop: 2 }}>
      <Box
        minHeight={"85vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ProductRow collection={row404} />
      </Box>
    </Container>
  );
};

export default Nope;
